// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-js": () => import("/home/circleci/project/src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("/home/circleci/project/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/home/circleci/project/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("/home/circleci/project/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("/home/circleci/project/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/project/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-corporations-js": () => import("/home/circleci/project/src/pages/investors-corporations.js" /* webpackChunkName: "component---src-pages-investors-corporations-js" */),
  "component---src-pages-investors-corporations-deepdive-valuation-js": () => import("/home/circleci/project/src/pages/investors-corporations/deepdive-valuation.js" /* webpackChunkName: "component---src-pages-investors-corporations-deepdive-valuation-js" */),
  "component---src-pages-investors-corporations-due-diligence-js": () => import("/home/circleci/project/src/pages/investors-corporations/due-diligence.js" /* webpackChunkName: "component---src-pages-investors-corporations-due-diligence-js" */),
  "component---src-pages-investors-corporations-scouting-rating-js": () => import("/home/circleci/project/src/pages/investors-corporations/scouting-rating.js" /* webpackChunkName: "component---src-pages-investors-corporations-scouting-rating-js" */),
  "component---src-pages-startups-js": () => import("/home/circleci/project/src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-pages-startups-financial-modelling-js": () => import("/home/circleci/project/src/pages/startups/financial-modelling.js" /* webpackChunkName: "component---src-pages-startups-financial-modelling-js" */),
  "component---src-pages-startups-rating-js": () => import("/home/circleci/project/src/pages/startups/rating.js" /* webpackChunkName: "component---src-pages-startups-rating-js" */),
  "component---src-pages-startups-valuation-js": () => import("/home/circleci/project/src/pages/startups/valuation.js" /* webpackChunkName: "component---src-pages-startups-valuation-js" */)
}

