module.exports = [{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Calibri:400,500,700"]}},
    },{
      plugin: require('/home/circleci/project/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MSHFXZJ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/home/circleci/project/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
